// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/routes/_index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/routes/_index.tsx");
  import.meta.hot.lastModified = "1726719770000";
}
// REMIX HMR END

import { AnchorLink } from '~/components/AnchorLink';
import { Footer } from '~/components/Footer';
import { Header } from '~/components/Header';
import { Button } from '~/components/ui/button';
import { AUTH_PAGES_URL } from '~/lib/constants';
const Homepage = () => {
  return <>
      <Header />
      <main className='w-full'>
        <section className='relative'>
          <div className='h-[100vh]'>
            <video className='w-full h-full object-cover aspect-video' autoPlay playsInline loop preload='auto' muted controls={false}>
              <source src='/assets/home-hero-video.mp4' type='video/mp4' />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className='absolute top-0 left-0 bottom-0 w-full'>
            <div className='mx-auto max-w-4xl px-4 py-12 md:py-20 flex flex-col justify-center items-center text-center h-full'>
              <h1 className='bg-gradient-to-r from-primary-300 via-secondary-500 to-ternary-600 bg-clip-text text-3xl font-extrabold text-transparent sm:text-5xl'>
                Sustainability Reporting and Accelerated Focus on ESG Adoption
              </h1>
              <p className='mx-auto mt-4 max-w-xl sm:text-xl/relaxed bg-gradient-to-b from-primary-500 via-primary-700 to-secondary-700 md:from-primary-50 md:via-primary-400 md:to-white bg-clip-text text-transparent'>
                Implementing ESG framework requires active engagement of
                different stakeholders starting from Board to Senior Management,
                executives and staff. All business processes need to be reviewed
                from ESG perspectives and re-orient them with ESG enabled
                business strategies of the organization.
              </p>
              <div className='mt-8 flex flex-wrap justify-center gap-4'>
                <Button asChild>
                  <AnchorLink to={AUTH_PAGES_URL.LOGIN}>Get Started</AnchorLink>
                </Button>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>;
};
_c = Homepage;
export default Homepage;
var _c;
$RefreshReg$(_c, "Homepage");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;